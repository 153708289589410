import React, {useEffect, useState} from 'react';



import {domAnimation, LazyMotion, motion, m} from "framer-motion";
import styles from './style.module.scss'
import TagsField from "../../base/tagsField";
import BgGradient from "../../BgGradient";
import {useMediaQuery} from "@mui/material";
import Orbit from "../../orbit";

const Index = () => {

  const isDesktop = useMediaQuery('(min-width: 1200px)')
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1199px)')
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <article className={styles.mainSection}>
      <LazyMotion features={domAnimation}>
        <BgGradient/>

        <div className={styles.content}>
          <>
            <motion.div
              initial={{
                opacity: 0,
                scale: .5,
              }}
              animate={{
                opacity: .25,
                scale: 1,

                transition: {
                  duration: 1,
                  delay: .1
                }
              }}

              className={styles.bgLogo}>
              <svg width="665" height="489" viewBox="0 0 665 489" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.15" filter="url(#filter0_dd_1303_2142)">
                  <path
                    d="M295.897 470.896L383.153 107.808H259.029C257.786 107.804 256.559 107.517 255.443 106.969C254.326 106.421 253.349 105.626 252.584 104.645C251.82 103.664 251.289 102.521 251.032 101.305C250.774 100.088 250.796 98.8286 251.097 97.6218L252.359 92.5739C258.453 67.6191 272.744 45.4309 292.944 29.5617C313.144 13.6925 338.085 5.06017 363.773 5.04688H652.676C653.919 5.05098 655.146 5.33785 656.262 5.88578C657.379 6.43372 658.356 7.22838 659.12 8.20965C659.884 9.19092 660.416 10.3331 660.673 11.5498C660.931 12.7666 660.909 14.026 660.608 15.2328L659.346 20.2807C653.248 45.3184 638.894 67.572 618.598 83.4518C598.303 99.3316 573.25 107.912 547.481 107.808H502.41L436.878 380.485C430.016 409.038 413.77 434.462 390.741 452.686C367.713 470.909 339.235 480.877 309.869 480.992H304.1C302.838 481.045 301.58 480.802 300.429 480.283C299.277 479.764 298.262 478.983 297.465 478.002C296.669 477.022 296.112 475.869 295.84 474.635C295.567 473.401 295.587 472.121 295.897 470.896Z"
                    fill="#F0F0F0"/>
                  <path
                    d="M94.5074 97.2231C107.857 41.9448 157.327 3 214.194 3H216.666C221.647 3 225.315 7.66019 224.146 12.502L133.933 386.05C120.584 441.328 71.1138 480.273 14.2463 480.273H11.7752C6.79417 480.273 3.12545 475.613 4.29476 470.771L94.5074 97.2231Z"
                    fill="#F0F0F0"/>
                </g>
                <defs>
                  <filter id="filter0_dd_1303_2142" x="0.221362" y="0.108892" width="664.485" height="488.601"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="0.963703"/>
                    <feGaussianBlur stdDeviation="1.92741"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1303_2142"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3.85481"/>
                    <feGaussianBlur stdDeviation="1.92741"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_1303_2142" result="effect2_dropShadow_1303_2142"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1303_2142" result="shape"/>
                  </filter>
                </defs>
              </svg>

            </motion.div>
          </>

          <div className={styles.labelWrapperMobile}>
            <>
              <motion.svg




                initial={{
                  marginBottom: '-100px',
                  opacity: 0,
                }}
                animate={{
                  marginBottom: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.2,
                    duration: .7,
                  }
                }}
                className={styles.LogoMobile} viewBox="0 0 260 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M39.6974 17.231C38.8843 15.6643 37.7119 14.3109 36.2755 13.2807C34.6654 12.1591 32.8628 11.341 30.9569 10.8671C28.6934 10.3009 26.3657 10.0305 24.0323 10.0626H11.2403C10.9108 10.0632 10.5911 10.1747 10.3333 10.3792C10.0754 10.5837 9.89446 10.869 9.81983 11.189L0.0220432 52.0832C-0.00509479 52.1905 -0.00720537 52.3026 0.0158796 52.4108C0.0389645 52.5191 0.0866292 52.6206 0.15521 52.7076C0.22379 52.7946 0.311462 52.8648 0.411494 52.9128C0.511527 52.9607 0.621259 52.9852 0.732259 52.9843H1.2165C3.84672 52.9799 6.3992 52.0947 8.46429 50.4709C10.5294 48.8471 11.9873 46.5787 12.6042 44.0299L13.7664 39.2027H17.6403C20.6821 39.2233 23.7184 38.9403 26.7036 38.3579C29.3084 37.8641 31.8038 36.9105 34.0722 35.5421C36.1547 34.2452 37.8734 32.4432 39.0679 30.3046C40.3369 27.8987 40.9702 25.2101 40.908 22.4926C40.9335 20.6673 40.5183 18.8627 39.6974 17.231V17.231ZM29.2782 26.5153C28.787 27.3513 28.0933 28.0511 27.2605 28.5507C26.249 29.1392 25.1388 29.5399 23.9838 29.7334C22.5271 29.9937 21.0491 30.1176 19.5692 30.1035H16.0262L18.5604 19.371L21.5869 19.2986C22.0469 19.2584 22.6037 19.2423 23.2575 19.2423C25.0313 19.1413 26.8015 19.4958 28.3985 20.2721C28.9456 20.6011 29.3878 21.0782 29.6736 21.6479C29.9593 22.2176 30.0769 22.8565 30.0126 23.4902C30.0348 24.545 29.7817 25.5873 29.2782 26.5153V26.5153Z"
                  fill="#F0F0F0"/>
                <path
                  d="M37.2418 52.081L47.0735 10.9601C47.1131 10.7995 47.2054 10.6566 47.3357 10.5543C47.466 10.452 47.6268 10.3961 47.7925 10.3955V10.3955C49.0026 10.3948 50.1971 10.6683 51.286 11.1954C52.3749 11.7224 53.3298 12.4893 54.0788 13.4383C54.8279 14.3873 55.3514 15.4936 55.6101 16.6739C55.8688 17.8542 55.8559 19.0777 55.5723 20.2523L49.9172 43.7326H59.7894C60.7899 43.7325 61.7774 43.9592 62.6774 44.3957C63.5774 44.8322 64.3663 45.467 64.9848 46.2522C65.6032 47.0375 66.035 47.9527 66.2476 48.9289C66.4602 49.905 66.448 50.9167 66.2119 51.8874L66.0827 52.4279C66.0431 52.5864 65.9517 52.7272 65.8229 52.828C65.6941 52.9288 65.5354 52.9839 65.3717 52.9844H37.9042C37.7983 52.9755 37.6955 52.944 37.6029 52.8921C37.5102 52.8401 37.4297 52.769 37.3669 52.6833C37.3041 52.5977 37.2605 52.4996 37.2389 52.3957C37.2173 52.2918 37.2183 52.1845 37.2418 52.081V52.081Z"
                  fill="#F0F0F0"/>
                <path
                  d="M107.516 41.9903C107.106 38.3767 106.648 34.7309 106.158 31.0931C105.668 27.4553 105.129 23.9143 104.551 20.4378C104.133 17.8808 103.273 13.5977 102.855 11.5651C102.788 11.2342 102.609 10.9368 102.348 10.7235C102.088 10.5103 101.761 10.3944 101.425 10.3955H94.1933C93.9601 10.3964 93.7305 10.4532 93.5236 10.5612C93.3167 10.6693 93.1386 10.8254 93.0041 11.0166C91.8631 12.6298 88.7695 17.0662 87.1785 19.4779C85.218 22.4462 83.1609 25.6404 81.0959 28.9717C79.0308 32.303 76.8533 35.8843 74.6677 39.6189C72.9 42.6114 69.0431 49.2982 67.5003 51.8874C67.4371 51.999 67.4041 52.1253 67.4047 52.2537C67.4052 52.3821 67.4393 52.5081 67.5036 52.6191C67.5678 52.7301 67.66 52.8222 67.7708 52.8864C67.8816 52.9505 68.0072 52.9843 68.1351 52.9844H72.4018C74.0981 52.9883 75.7655 52.5429 77.2355 51.6932C78.7056 50.8435 79.9263 49.6195 80.7745 48.1447V48.0722C81.578 46.7171 82.3815 45.3458 83.0806 44.0391H97.4074C97.594 46.4755 98.6917 48.7509 100.48 50.4085C102.269 52.0662 104.615 52.9835 107.05 52.9763H107.917C108.02 52.9768 108.121 52.9557 108.215 52.9144C108.309 52.873 108.393 52.8124 108.462 52.7363C108.531 52.6603 108.583 52.5706 108.615 52.473C108.647 52.3753 108.659 52.272 108.649 52.1697C108.44 50.1371 107.845 44.878 107.516 41.9903ZM87.6205 35.3358C88.9222 32.9805 90.2882 30.6494 91.6381 28.3829C92.9076 26.3072 94.1933 24.2208 95.495 22.1236C95.7682 24.2127 95.9851 26.3018 96.1458 28.3667C96.3387 30.7866 96.5315 33.1338 96.7083 35.3358H87.6205Z"
                  fill="#F0F0F0"/>
                <path
                  d="M113.003 52.0804L120.782 19.5907H109.716C109.605 19.5903 109.496 19.5647 109.396 19.5156C109.297 19.4666 109.21 19.3955 109.142 19.3077C109.073 19.2199 109.026 19.1177 109.003 19.0088C108.98 18.8999 108.982 18.7872 109.009 18.6793L109.121 18.2276C109.665 15.9946 110.939 14.0091 112.74 12.5891C114.541 11.1691 116.764 10.3967 119.054 10.3955H144.811C144.922 10.3959 145.031 10.4215 145.131 10.4706C145.23 10.5196 145.317 10.5907 145.386 10.6785C145.454 10.7663 145.501 10.8685 145.524 10.9774C145.547 11.0863 145.545 11.199 145.518 11.307L145.406 11.7587C144.862 13.9991 143.582 15.9904 141.773 17.4113C139.963 18.8322 137.73 19.6 135.433 19.5907H131.414L125.572 43.9902C124.96 46.5452 123.512 48.8203 121.459 50.4509C119.406 52.0815 116.867 52.9735 114.249 52.9838H113.734C113.622 52.9885 113.51 52.9668 113.407 52.9203C113.304 52.8739 113.214 52.804 113.143 52.7163C113.072 52.6285 113.022 52.5253 112.998 52.415C112.974 52.3046 112.975 52.19 113.003 52.0804Z"
                  fill="#F0F0F0"/>
                <path
                  d="M180.152 20.211C179.364 18.1055 178.146 16.1862 176.576 14.5746C175.037 13.0041 173.183 11.7749 171.135 10.9674C168.927 10.1245 166.58 9.70385 164.217 9.72741C160.693 9.67577 157.212 10.4887 154.077 12.0947C151.173 13.6089 148.601 15.6867 146.513 18.206C144.454 20.7143 142.856 23.5655 141.791 26.6283C140.72 29.6572 140.174 32.8455 140.176 36.057C140.142 38.6083 140.569 41.1448 141.436 43.5453C142.224 45.6521 143.445 47.5718 145.02 49.1816C146.57 50.7462 148.436 51.9648 150.493 52.7566C152.701 53.596 155.048 54.0139 157.411 53.9886C160.924 54.038 164.397 53.2397 167.534 51.6616C170.445 50.1712 173.02 48.1024 175.099 45.5824C177.162 43.0826 178.756 40.2323 179.805 37.1682C180.859 34.1269 181.405 30.9333 181.419 27.7153C181.458 25.1578 181.029 22.6147 180.152 20.211V20.211ZM169.52 34.5111C168.935 36.3845 168.046 38.1497 166.889 39.7367C165.836 41.1826 164.512 42.4105 162.99 43.352C161.568 44.2197 159.933 44.6769 158.267 44.6725C155.676 44.6725 153.876 43.8674 152.753 42.257C151.5 40.2122 150.889 37.8394 151.002 35.4451C150.999 33.3637 151.317 31.2942 151.946 29.3096C152.53 27.428 153.419 25.6546 154.578 24.0597C155.629 22.6096 156.956 21.381 158.485 20.4445C159.903 19.5758 161.536 19.1186 163.199 19.124C165.791 19.124 167.599 19.9291 168.713 21.5395C169.965 23.571 170.584 25.9282 170.489 28.3111C170.493 30.4156 170.166 32.5076 169.52 34.5111V34.5111Z"
                  fill="#F0F0F0"/>
                <path
                  d="M215.961 17.7408C215.261 16.1725 214.194 14.7942 212.85 13.7212C211.315 12.5141 209.565 11.6059 207.693 11.0442C205.372 10.3533 202.958 10.0225 200.536 10.0634H189.632C189.302 10.065 188.983 10.1767 188.724 10.3807C188.466 10.5847 188.283 10.8693 188.205 11.1889L178.422 52.0839C178.398 52.1915 178.398 52.3031 178.423 52.4106C178.448 52.518 178.496 52.6185 178.565 52.7049C178.634 52.7912 178.721 52.8611 178.82 52.9094C178.92 52.9578 179.028 52.9833 179.139 52.9843H179.622C182.254 52.9849 184.809 52.1017 186.877 50.4771C188.944 48.8525 190.403 46.5811 191.018 44.0287L192.477 37.9994H196.152C197.297 40.0522 198.252 42.2049 199.005 44.4307C199.206 44.9934 199.448 45.7009 199.714 46.4646C200.369 48.3705 201.605 50.0242 203.25 51.1935C204.895 52.3628 206.866 52.989 208.885 52.9843H212.327C212.443 52.984 212.557 52.9561 212.66 52.903C212.764 52.8499 212.853 52.7731 212.921 52.6789C212.988 52.5847 213.033 52.4758 213.05 52.3612C213.067 52.2465 213.057 52.1295 213.02 52.0196C212.318 49.9616 210.65 45.114 209.748 42.847C208.708 40.2503 207.676 37.9672 206.685 36.0459C208.322 35.5033 209.876 34.7377 211.303 33.7708C212.627 32.8613 213.785 31.733 214.728 30.4346C215.566 29.2761 216.168 27.9652 216.501 26.5758C216.816 25.259 216.972 23.9098 216.969 22.5562C216.971 20.8988 216.628 19.2588 215.961 17.7408V17.7408ZM205.855 25.7317C205.528 26.4356 205.022 27.0423 204.388 27.4922C203.508 28.1025 202.521 28.5441 201.479 28.7946C199.966 29.1659 198.411 29.3389 196.853 29.3091H194.588L196.942 19.4129L198.199 19.3325C198.908 19.3325 199.593 19.2763 200.238 19.2763C201.288 19.2473 202.337 19.3774 203.349 19.6621C204.015 19.8504 204.634 20.1794 205.162 20.6268C205.565 20.9888 205.868 21.4485 206.04 21.9613C206.213 22.5158 206.3 23.0932 206.298 23.6737C206.295 24.3829 206.144 25.0836 205.855 25.7317Z"
                  fill="#F0F0F0"/>
                <path
                  d="M254.391 42.0146C253.981 38.401 253.524 34.7552 253.034 31.1174C252.544 27.4796 252.006 23.9386 251.428 20.4622C251.011 17.9052 250.152 13.6222 249.743 11.5895C249.677 11.2487 249.493 10.9422 249.225 10.7239C248.956 10.5055 248.619 10.3894 248.274 10.3958H241.049C240.816 10.3966 240.587 10.4534 240.38 10.5615C240.173 10.6695 239.995 10.8256 239.861 11.0168C238.721 12.63 235.631 17.0664 234.041 19.4781C232.082 22.4464 230.027 25.6406 227.964 28.9718C225.901 32.3031 223.726 35.8844 221.543 39.619C219.776 42.6115 215.923 49.2982 214.382 51.8874C214.319 51.999 214.286 52.1253 214.287 52.2537C214.287 52.3821 214.321 52.5081 214.385 52.6191C214.45 52.7301 214.542 52.8222 214.652 52.8864C214.763 52.9505 214.888 52.9843 215.016 52.9844H219.279C220.973 52.9883 222.639 52.5429 224.108 51.6932C225.576 50.8435 226.796 49.6195 227.643 48.1448L227.683 48.0722C228.486 46.7171 229.289 45.3459 229.987 44.0392H244.3C244.487 46.4755 245.583 48.7509 247.37 50.4086C249.157 52.0662 251.501 52.9835 253.933 52.9763H254.8C254.902 52.9768 255.004 52.9557 255.097 52.9144C255.191 52.873 255.275 52.8124 255.344 52.7363C255.413 52.6603 255.465 52.5706 255.497 52.473C255.529 52.3753 255.541 52.272 255.53 52.1697C255.314 50.1613 254.696 44.9022 254.391 42.0146ZM234.515 35.3601C235.815 33.0048 237.18 30.6738 238.528 28.4072C239.797 26.3315 241.081 24.2451 242.382 22.148C242.655 24.2371 242.871 26.3262 243.032 28.3911C243.235 30.8378 243.422 33.1608 243.594 35.3601H234.515Z"
                  fill="#F0F0F0"/>
                <path
                  d="M134.813 82.6299C131.627 82.6299 129.185 81.7459 127.5 79.9654C125.814 78.1848 125.095 75.8067 125.331 72.8184C125.616 69.2698 126.967 66.269 129.384 63.8286C131.801 61.3882 134.701 60.168 138.097 60.168C140.601 60.168 142.621 60.7781 144.195 62.0107C145.769 63.2434 146.525 65.0613 146.501 67.4644H142.448C142.423 64.7375 140.836 63.3679 137.688 63.3679C135.432 63.3679 133.561 64.2769 132.086 66.0823C130.598 67.8877 129.756 70.1165 129.545 72.7686C129.384 74.8479 129.768 76.479 130.71 77.6619C131.652 78.8448 133.139 79.43 135.172 79.43C138.605 79.43 140.799 77.5872 141.766 73.9141H136.287L137.019 70.8635H146.488C145.298 78.6829 141.406 82.605 134.825 82.6175L134.813 82.6299Z"
                  fill="#F0F0F0"/>
                <path
                  d="M157.834 61.4165H164.86C167.036 61.4165 168.713 61.8992 169.904 62.8766C171.095 63.8541 171.606 65.2418 171.423 67.0398C171.278 68.8137 170.646 70.2617 169.527 71.3598C168.397 72.458 166.987 73.1578 165.261 73.4595L169.296 82.6185H165.139L161.383 74.0629H158.758L156.716 82.6306H152.741L157.834 61.4286V61.4165ZM162.72 71.2271C165.686 71.2271 167.279 69.9238 167.485 67.3415C167.583 66.352 167.352 65.6279 166.792 65.1573C166.233 64.6988 165.37 64.4574 164.191 64.4695H161.08L159.426 71.2392H162.708L162.72 71.2271Z"
                  fill="#F0F0F0"/>
                <path
                  d="M200.119 69.9313C199.821 73.6252 198.444 76.66 195.986 79.0479C193.529 81.4359 190.575 82.6299 187.149 82.6299C184.158 82.6299 181.788 81.7469 180.025 79.9434C178.263 78.1524 177.506 75.8142 177.742 72.9163C178.027 69.2846 179.417 66.2623 181.887 63.8246C184.357 61.3868 187.311 60.168 190.749 60.168C193.814 60.168 196.21 61.0759 197.91 62.8918C199.61 64.7076 200.355 67.0583 200.119 69.9313V69.9313ZM195.899 70.0308C196.048 68.1155 195.651 66.5359 194.695 65.2797C193.74 64.0236 192.3 63.4017 190.389 63.4017C188.068 63.4017 186.132 64.3221 184.593 66.1628C183.041 67.9911 182.16 70.2174 181.949 72.8292C181.788 74.8441 182.222 76.4485 183.24 77.6301C184.258 78.8116 185.71 79.3962 187.584 79.3962C189.855 79.3962 191.766 78.5007 193.293 76.7221C194.819 74.9436 195.688 72.7173 195.899 70.0433V70.0308Z"
                  fill="#F0F0F0"/>
                <path
                  d="M214.17 82.6306C211.118 82.6306 208.936 81.8925 207.637 80.3806C206.338 78.8806 206.061 76.6782 206.805 73.7735L209.932 61.4284H214.081L210.916 73.8568C210.399 75.8211 210.437 77.2616 211.042 78.1544C211.648 79.0473 212.82 79.4996 214.548 79.4996C216.061 79.4996 217.247 79.0949 218.142 78.2973C219.025 77.4997 219.706 76.1902 220.16 74.3807L223.464 61.4165H227.613L224.208 74.7854C222.896 80.0115 219.542 82.6306 214.144 82.6306H214.17Z"
                  fill="#F0F0F0"/>
                <path
                  d="M239.031 61.4165H245.795C248.049 61.4165 249.771 61.8995 250.973 62.8895C252.174 63.8796 252.695 65.2923 252.546 67.1275C252.335 69.6027 251.382 71.45 249.684 72.6936C247.975 73.9373 245.857 74.553 243.317 74.553H239.873L237.903 82.6306H233.853L239.043 61.4165H239.031ZM243.54 71.7036C245.088 71.7036 246.278 71.3414 247.133 70.6048C247.987 69.8683 248.47 68.8179 248.582 67.4294C248.743 65.4251 247.578 64.435 245.088 64.435H242.351L240.567 71.7036H243.552H243.54Z"
                  fill="#F0F0F0"/>
              </motion.svg>
            </>

            <div className={styles.OrbitMobile}>
              <Orbit/>
            </div>
          </div>

        </div>

        <motion.svg
          onClick={()=>{
            window.scrollTo({top:window.outerHeight - 50, behavior: 'smooth'})
          }}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              delay: 1.5,
              duration: 1
            }
          }}
          className={styles.arrow} width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          {/*2*/}
          <motion.path
            initial={{
              opacity: .15
            }}
            animate={{
              opacity: .45
            }}
            transition={{
              duration: 1.5,
              repeatType: "mirror",
              repeat: Infinity,
              delay: .1
            }}
            d="M21.375 8.75L15.375 14.375L9.375 8.75" stroke="#0BA438" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>

          {/*3*/}
          <motion.path
            initial={{
              opacity: .15
            }}
            animate={{
              opacity: .45
            }}
            transition={{
              duration: 1.5,
              repeatType: "mirror",
              repeat: Infinity,
              delay: .5
            }}
            d="M24.2517 14.2114L15.1892 22.5081L6.12671 14.2114" stroke="#4D69D4" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>

          {/*1*/}
          <motion.path
            initial={{
              opacity: .15
            }}
            animate={{
              opacity: .45
            }}
            transition={{
              duration: 1.5,
              repeatType: "mirror",
              repeat: Infinity,
              delay: .1
            }}
            d="M18.3125 3.375L15.5 6.1875L12.6875 3.375" stroke="#E49453" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </motion.svg>



        <m.div className={styles.tagWrapper}
               initial={{
                 opacity: 0
               }}
               animate={{
                 opacity: 1,
                 transition: {
                   duration: 1,
                   type: 'just'
                 }
               }}
        >
          <TagsField/>
        </m.div>
      </LazyMotion>
    </article>
  );
};

export default Index;