import React, { useRef, useState} from 'react';

import styles from './styles.module.scss'

import bg from './bg.webp'

import {motion} from "framer-motion";
import {Alert, Box, Checkbox, CircularProgress, Snackbar} from "@mui/material";

import emailjs from '@emailjs/browser';

const Index = () => {

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [sending, setSending] = useState(true)

  const [terms, setTerms] = useState(false)
  const handleChange = (event) => {
    setTerms(event.target.checked);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(false)

    emailjs.sendForm('service_z4k5x7l', 'template_ss4vblc', form.current, 'gcaxzmryhrtenfxa')
      .then(() => {
        handleClick()
        setSending(true)
        document.getElementById("myForm").reset();
      }, () => {
        handleClick()
        setSending(true)
        document.getElementById("myForm").reset();
      });
  };

  return (
    <footer className={styles.footerWrapper} style={{backgroundImage: `url(${bg})`}}>

      <div className={styles.footerLabel}>
        <span>
          Готовы <br/> к сотрудничеству
        </span>

        <div className={styles.labelStars}>

          <svg className={styles.orbitOrange} width="456" height="199" viewBox="0 0 456 199" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M436.786 39.53C437.39 41.6104 436.831 44.1569 434.828 47.2231C432.83 50.2805 429.495 53.6933 424.885 57.3928C415.673 64.7851 401.592 73.1467 383.66 81.95C347.813 99.5483 296.779 118.813 239.06 135.559C181.341 152.304 127.921 163.344 88.221 167.664C68.3619 169.824 51.9926 170.297 40.2544 168.983C34.3799 168.325 29.7361 167.227 26.4124 165.714C23.079 164.196 21.2445 162.343 20.6409 160.263C20.0374 158.183 20.5957 155.636 22.5991 152.57C24.5968 149.513 27.9319 146.1 32.5422 142.4C41.7545 135.008 55.835 126.646 73.767 117.843C109.614 100.245 160.648 80.9797 218.367 64.2342C276.086 47.4886 329.507 36.4491 369.206 32.1296C389.065 29.9689 405.435 29.4963 417.173 30.8104C423.047 31.468 427.691 32.566 431.015 34.0795C434.348 35.5974 436.183 37.4497 436.786 39.53Z" stroke="orange" strokeWidth="2.30075"/>

            <g>
              <g className={styles.star} transform="translate(-10,-12) scale(0.03)">
                <motion.path
                  initial={{
                    rotate: 0
                  }}
                  animate={{
                    rotate: 360
                  }}
                  transition={{
                    duration: 16,
                    repeat: Infinity
                  }}
                  id="path" d="M435 760 c-24 -112 -44 -185 -54 -194 -9 -7 -95 -31 -191 -51 -96
-21 -176 -38 -178 -40 -1 -1 79 -19 178 -40 112 -24 185 -44 194 -54 7 -9 31
-95 51 -191 21 -96 38 -176 40 -178 1 -1 19 79 40 178 24 112 44 185 54 194 9
7 95 31 191 51 96 21 176 38 178 40 1 1 -79 19 -178 40 -112 24 -185 44 -194
54 -7 9 -31 95 -51 191 -21 96 -38 176 -40 178 -1 1 -19 -79 -40 -178z" fill='orange'>
                </motion.path>
              </g>

              <animateMotion begin="0s" dur="12s" rotate="auto" repeatCount="indefinite" additive="sum">
                <mpath xlinkHref="#traceP"  />
              </animateMotion>

            </g>
          </svg>


          <svg className={styles.orbitGreen} width="460" height="199" viewBox="0 0 460 199" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="traceG" d="M436.786 39.53C437.39 41.6104 436.831 44.1569 434.828 47.2231C432.83 50.2805 429.495 53.6933 424.885 57.3928C415.673 64.7851 401.592 73.1467 383.66 81.95C347.813 99.5483 296.779 118.813 239.06 135.559C181.341 152.304 127.921 163.344 88.221 167.664C68.3619 169.824 51.9926 170.297 40.2544 168.983C34.3799 168.325 29.7361 167.227 26.4124 165.714C23.079 164.196 21.2445 162.343 20.6409 160.263C20.0374 158.183 20.5957 155.636 22.5991 152.57C24.5968 149.513 27.9319 146.1 32.5422 142.4C41.7545 135.008 55.835 126.646 73.767 117.843C109.614 100.245 160.648 80.9797 218.367 64.2342C276.086 47.4886 329.507 36.4491 369.206 32.1296C389.065 29.9689 405.435 29.4963 417.173 30.8104C423.047 31.468 427.691 32.566 431.015 34.0795C434.348 35.5974 436.183 37.4497 436.786 39.53Z" stroke="#0BA438" strokeWidth="2.30075"/>

            <g>
              <g id="star" transform="translate(-10,-12) scale(0.03)">
                <motion.path
                  initial={{
                    rotate: 0
                  }}
                  animate={{
                    rotate: 360
                  }}
                  transition={{
                    duration: 12,
                    repeat: Infinity
                  }}
                  id="path" d="M435 760 c-24 -112 -44 -185 -54 -194 -9 -7 -95 -31 -191 -51 -96
-21 -176 -38 -178 -40 -1 -1 79 -19 178 -40 112 -24 185 -44 194 -54 7 -9 31
-95 51 -191 21 -96 38 -176 40 -178 1 -1 19 79 40 178 24 112 44 185 54 194 9
7 95 31 191 51 96 21 176 38 178 40 1 1 -79 19 -178 40 -112 24 -185 44 -194
54 -7 9 -31 95 -51 191 -21 96 -38 176 -40 178 -1 1 -19 -79 -40 -178z" fill='#0BA438'>
                </motion.path>

              </g>
              <animateMotion begin="0s" dur="20s" rotate="auto" repeatCount="indefinite" additive="sum">
                <mpath xlinkHref="#traceG"  />
              </animateMotion>

            </g>
          </svg>

          <svg className={styles.orbitBlue} width="457" height="199" viewBox="0 0 457 199" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="traceP" d="M436.786 39.53C437.39 41.6104 436.831 44.1569 434.828 47.2231C432.83 50.2805 429.495 53.6933 424.885 57.3928C415.673 64.7851 401.592 73.1467 383.66 81.95C347.813 99.5483 296.779 118.813 239.06 135.559C181.341 152.304 127.921 163.344 88.221 167.664C68.3619 169.824 51.9926 170.297 40.2544 168.983C34.3799 168.325 29.7361 167.227 26.4124 165.714C23.079 164.196 21.2445 162.343 20.6409 160.263C20.0374 158.183 20.5957 155.636 22.5991 152.57C24.5968 149.513 27.9319 146.1 32.5422 142.4C41.7545 135.008 55.835 126.646 73.767 117.843C109.614 100.245 160.648 80.9797 218.367 64.2342C276.086 47.4886 329.507 36.4491 369.206 32.1296C389.065 29.9689 405.435 29.4963 417.173 30.8104C423.047 31.468 427.691 32.566 431.015 34.0795C434.348 35.5974 436.183 37.4497 436.786 39.53Z" stroke="#4D69D4" strokeWidth="2.30075"/>

            <g>
              <g id="star" transform="translate(-5,-10) scale(0.02)">
                <motion.path
                  initial={{
                    rotate: 0
                  }}
                  animate={{
                    rotate: 360
                  }}
                  transition={{
                    duration: 12,
                    repeat: Infinity
                  }}
                  id="path" d="M435 760 c-24 -112 -44 -185 -54 -194 -9 -7 -95 -31 -191 -51 -96
-21 -176 -38 -178 -40 -1 -1 79 -19 178 -40 112 -24 185 -44 194 -54 7 -9 31
-95 51 -191 21 -96 38 -176 40 -178 1 -1 19 79 40 178 24 112 44 185 54 194 9
7 95 31 191 51 96 21 176 38 178 40 1 1 -79 19 -178 40 -112 24 -185 44 -194
54 -7 9 -31 95 -51 191 -21 96 -38 176 -40 178 -1 1 -19 -79 -40 -178z" fill='#4D69D4'>
                </motion.path>

              </g>
              <animateMotion begin="0s" dur="32s" rotate="auto" repeatCount="indefinite" additive="sum">
                <mpath xlinkHref="#traceP"  />
              </animateMotion>

            </g>
          </svg>

        </div>


      </div>

      <form ref={form} onSubmit={sendEmail} id="myForm">

        <div className={styles.footerForm}>

          <div className={styles.formField}>
            <span>ФИО</span>
            <input disabled={!sending} required type="text" name="user_name" placeholder='Укажите ФИО'/>
          </div>

          <div className={styles.formField}>
            <span>Почта</span>
            <input disabled={!sending} required type="email" name="user_email" placeholder='Укажите почту'/>
          </div>

          <div className={styles.formField}>
            <span>Сообщение</span>
            <textarea disabled={!sending} required name="message" placeholder='Введите текст сообщения'/>
          </div>

          <div className={ styles.formFooter}>

            <motion.button
              disabled={!terms}
              type="submit" value="Send"

              className={!terms && sending ? styles.buttonDisabled : styles.buttonEnabled}

              initial={{
                scale: 0.98
              }}
              whileHover={{
                scale: terms ? 1 : 0.98
              }}
              whileTap={{
                scale: terms ? 0.98 : 0.96
              }}
            >
              {
                sending ? 'ОТПРАВИТЬ' : <CircularProgress color='inherit' size={30}/>
              }
            </motion.button>


            <Box display='flex' alignItems='center' className={styles.toolWrapper}>
              <Checkbox
                checked={terms} onChange={handleChange}
                sx={{


                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },
                }}
              />
              <div className={styles.formFooterTerms}>
                Нажимая кнопку “ОТПРАВИТЬ” Вы соглашаетесь с <span> Политикой конфиденциальности</span>
              </div>
            </Box>





          </div>


        </div>

      </form>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          <span className={styles.snap}>Сообщение успешно отправлено!</span>
        </Alert>
      </Snackbar>


    </footer>
  );
};

export default Index;