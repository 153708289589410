import React from 'react';
import Marquee from "react-fast-marquee";

import styles from './style.module.scss'

const tags = [
  '#Цифровые технологии',
  '#Инновационная страна',
  '#IT-оптимизации',
  '#Образовательные технологии',
  '#Цифровое управление',
  '#Социальное развитие',
  '#Образовательные технологии ',
  '#Прогресcивные технологии',
  '#Информационная инфраструктура',
  '#IT-Экосистема',
  // '#Исследовательское лидерство',
  // '#Лучшая страна',
  '#Инновационная страна',
  '#Интеграции технологий',
  '#Програмные продукты',
]

const TagsField = () => {
  return (
    <Marquee delay={.6} gradientColor={[0,0,0]} gradient={false} className={styles.tagsContainer} speed={50}>
      {
        tags.map((item, index)=>(
          <div className={styles.tagBlock} key={index}>
            {item}
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.1374 0.308743L14.4161 4.68153C14.7342 9.67137 18.6888 13.6594 23.6758 14.0195L28.0461 14.335L23.6733 14.6137C18.6835 14.9317 14.6954 18.8864 14.3354 23.8734L14.0199 28.2437L13.7412 23.8709C13.4231 18.881 9.46846 14.893 4.48147 14.533L0.111179 14.2175L4.48396 13.9387C9.47381 13.6207 13.4619 9.66603 13.8219 4.67903L14.1374 0.308743Z" fill="#F0F0F0"/>
            </svg>
          </div>
        ))
      }

    </Marquee>
  );
};

export default TagsField;