import React, {useRef} from 'react';
import styles from './style.module.scss'
import classNames from "classnames";

import {AnimateSharedLayout, useInView} from "framer-motion";

import {motion} from "framer-motion";

import mk1 from '../../../../images/FINMK1.webp'
import mk2 from '../../../../images/FINMK2.webp'
import mk3 from '../../../../images/FINMK3.webp'
import SectionList from "../../../sectionList";
import {Parallax} from "react-parallax";

import placeholderMobile from '../../../../images/FINPLC1.webp'
import placeholderTablet from '../../../../images/FINPLCTAB.webp'
import placeholdermk1 from "../../../../images/ENGPLC1.webp";
import {LazyLoadImage} from "react-lazy-load-image-component";

const Index = ({blockArray, logoInitial, logoAnimate}) => {


  const ref = useRef(null)
  const isMokapRef= useInView(ref, {once: true, margin: '100px'})
  const isLogoRef= useInView(ref, {once: true, margin: '300px'})



  return (

    <div className={classNames(styles.sectionWrapper)}>

        <div className={styles.sectionHeader}>
          <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={require('./FINBG.webp')}
            bgImageAlt="the dog"
            strength={-50}
          >
            <div className={styles.headerContent}>
              {
                isLogoRef ?
                  <>
                    <motion.div className={styles.headerLogo}>
                      <motion.svg
                        initial={{scale: 0}} animate={{scale: 1}} transition={{duration: 1, type: 'spring'}}
                        width="351" height="347" viewBox="0 0 351 347" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M149.622 72.5083L47.7754 173.499L93.9165 10.157C94.7505 7.23552 96.5241 4.66316 98.9682 2.83015C101.412 0.997143 104.394 0.00351557 107.46 1.91578e-05H224.711C228.978 -0.0023489 233.242 0.214818 237.487 0.650642C241.137 1.0121 244.788 1.51814 248.438 2.16876C259.845 4.25403 270.903 7.90373 281.292 13.0125C287.942 16.3241 294.271 20.2321 300.201 24.6875C304.16 27.6319 307.938 30.8063 311.517 34.1938L204.378 140.281L149.622 72.5083Z" fill="#4D69D4"/>
                        <path d="M350.873 124.921C350.885 150.793 342.781 176.03 327.682 197.145C312.583 218.26 291.234 234.21 266.585 242.792C262.114 244.355 257.556 245.658 252.932 246.696C245.971 248.264 238.888 249.244 231.76 249.623H228.475H149.224C141.22 277.676 124.178 302.371 100.692 319.95C77.2066 337.528 48.5638 347.027 19.1237 347H7.88043C6.79746 347.001 5.72898 346.753 4.75886 346.277C3.78873 345.8 2.94335 345.107 2.28904 344.253C1.63474 343.398 1.18931 342.405 0.987728 341.352C0.786141 340.298 0.833873 339.213 1.12718 338.18L18.4666 276.733L147.362 149.211L202.119 216.875L341.893 78.4375C343.061 81.3653 344.156 84.3292 345.105 87.3655C348.958 99.5178 350.903 112.184 350.873 124.921Z" fill="#4D69D4"/>
                      </motion.svg>

                      <motion.div className={styles.headerLogoTitle}>
                        <motion.span
                          initial={{marginBottom: '-100px', position: 'absolute', opacity: 0}}
                          animate={{marginBottom: 0, position: 'relative', opacity: 1}}
                          transition={{duration: 1, type: 'spring'}}
                        >FINANCE</motion.span>
                        <motion.span
                          initial={{marginBottom: '-150px', position: 'absolute', opacity: 0}}
                          animate={{marginBottom: 0, position: 'relative', opacity: 1}}
                          transition={{duration: 1, type: 'spring'}}
                        >IT-платформа для взаимодействия финансовых технологий с капиталами
                        </motion.span>
                      </motion.div>
                    </motion.div>
                  </>: null
              }
            </div>

          </Parallax>


          <motion.div className={styles.headerMokaps}>
            <AnimateSharedLayout>
              {
                isMokapRef ?
                  <>
                    <motion.div
                      initial={{bottom: '-150px', opacity: 0}}
                      animate={{bottom: '50px', opacity: 1}}
                      transition={{type: 'spring', duration: 1, delay: .1}}
                      className={styles.mokapBlock1}>
                      <LazyLoadImage
                        placeholderSrc={placeholderMobile}
                        effect='blur'
                        src={mk1} alt=""/>
                    </motion.div>

                    <motion.div
                      initial={{bottom: '-200px', opacity: 0}}
                      animate={{bottom: '-50px', opacity: 1}}
                      transition={{type: 'spring', duration: 1, delay: .2}}
                      className={styles.mokapBlock2}>
                      <LazyLoadImage
                        placeholderSrc={placeholderMobile}
                        effect='blur'
                        src={mk2} alt=""/>
                    </motion.div>
                    <motion.div
                      initial={{bottom: '-120px', opacity: 0}}
                      animate={{bottom: '35px', opacity: 1}}
                      transition={{type: 'spring', duration: 1}}
                      className={styles.mokapBlock3}>
                      <LazyLoadImage
                        placeholderSrc={placeholderTablet}
                        effect='blur'
                        src={mk3} alt=""/>
                    </motion.div>
                  </>:null
              }
            </AnimateSharedLayout>

          </motion.div>


        </div>

        <div ref={ref} className={styles.ContentBody} >
            <span className={styles.ContentBodyTitle}>PLATORA.Finance</span>
            <SectionList list={blockArray} id={'FIN'}/>
        </div>

    </div>

  );
};

export default Index;