import React, {useState} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";

import styles from './style.module.scss'
import {domAnimation, LazyMotion, motion, m} from "framer-motion";

import mainLogo from './mainLogo.svg'

const Layout = () => {

  const location = useLocation()



  return (
    <>
      <LazyMotion features={domAnimation}>
        <m.header
          initial={{
            top: '-80px',
          }}
          animate={{
            top: 0,
            transition: {
              delay: location.pathname === '/about' ? .1 : 1.2,
              duration: 1,
            }
          }}
          className={styles.mainHeader}>
          <nav>

            <Link to={'/'} onClick={()=>{
              window.scrollTo(0, 0)
            }}>
              <img className={styles.logo} src={mainLogo} alt="Logo"/>
            </Link>

            <m.div
              initial={{
                scale: 1
              }}
              whileHover={{
                scale: 1.05
              }}
              whileTap={{
                scale: 0.96
              }}
            >
              <Link to={location.pathname === '/about' ? '/': '/about'} onClick={()=>window.scrollTo(0,0)} className={styles.headerLink}>
                <div className={styles.headerButton}>
                  {
                    location.pathname === '/about' ? 'НАЗАД' : 'О НАС'
                  }
                </div>
              </Link>
            </m.div>
          </nav>
        </m.header>
      </LazyMotion>

         <Outlet/>


    </>

  );
};

export default Layout;