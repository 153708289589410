import React, {useEffect, useRef, useState} from 'react';
import styles from './style.module.scss'

import {AnimatePresence, useInView} from "framer-motion";

import {motion} from "framer-motion";

import mk1 from '../../../../images/ENGMK1.webp'
import mk2 from '../../../../images/ENGMK2.webp'
import mk3 from '../../../../images/ENGMK3.webp'
import placeholdermk1 from '../../../../images/ENGPLC1.webp'
import SectionList from "../../../sectionList";

import {Parallax} from "react-parallax";

import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholdermk2 from "../../../../images/EDUPLC2.webp";



const Index = ({blockArray, logoInitial, logoAnimate}) => {

  const ref = useRef(null)
  const isMokapRef= useInView(ref, {once: true, margin: '100px'})
  const isLogoRef= useInView(ref, {once: true, margin: '300px'})


  return (

    <div className={styles.sectionWrapper}>

      <div className={styles.sectionHeader}>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={require('./ENGBG.webp')}
          bgImageAlt="the dog"
          strength={-50}
        >
          <div className={styles.headerContent}>
            <AnimatePresence>
              {
                isLogoRef ?
                  <>
                    <motion.div className={styles.headerLogo}>
                      <motion.svg
                        initial={{scale: 0}} animate={{scale: 1}} transition={{duration: 1, type: 'spring'}}
                        width="346" height="346" viewBox="0 0 346 346" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.283473 337.224C-1.02074 341.611 2.34277 345.999 6.94184 345.999H18.1306C72.0839 345.999 120.134 313.641 140.933 265.035L5.15714 319.879L0.283473 337.224Z" fill="#E19248"/>
                        <path d="M105.377 0.0685699C99.1308 0.0685699 93.708 4.18187 91.9919 10.1462L78.8125 57.1066L220.148 0L105.309 0L105.377 0.0685699Z" fill="#E19248"/>
                        <path d="M287.757 19.1272L64.1868 109.346L56.4302 136.974L49.2227 162.545L322.284 52.3079C314.321 41.202 302.995 28.6563 287.757 19.0586V19.1272Z" fill="#E19248"/>
                        <path d="M341.234 91.0391L34.6062 214.781L19.7793 267.637L345.284 136.217C345.627 132.378 345.833 128.539 345.833 124.563C345.833 112.908 344.186 101.665 341.234 91.0391Z" fill="#E19248"/>
                        <path d="M222.137 249.133C222.961 249.133 223.854 249.133 224.746 249.064H224.883C225.981 249.064 227.08 248.996 228.109 248.927C235.317 248.516 242.25 247.556 249.045 245.979C253.645 244.951 258.106 243.648 262.568 242.072C289.751 232.542 312.609 213.827 327.504 189.695L180.334 249.133H222.137Z" fill="#E19248"/>
                      </motion.svg>

                      <motion.div className={styles.headerLogoTitle}>
                        <motion.span
                          initial={{marginBottom: '-100px', position: 'absolute', opacity: 0}}
                          animate={{marginBottom: 0, position: 'relative', opacity: 1}}
                          transition={{duration: 1, type: 'spring'}}
                        >ENGINEERING</motion.span>
                        <motion.span
                          initial={{marginBottom: '-150px', position: 'absolute', opacity: 0}}
                          animate={{marginBottom: 0, position: 'relative', opacity: 1}}
                          transition={{duration: 1, type: 'spring'}}
                        >Стандартизация и автоматизация безнес-процессов строительной отрасли</motion.span>
                      </motion.div>


                    </motion.div>
                  </>: null
              }
            </AnimatePresence>
          </div>
        </Parallax>

        <motion.div className={styles.headerMokaps}>
          <AnimatePresence>
            {
              isMokapRef ?
              <>
                <motion.div
                  initial={{bottom: -120, opacity: 0}}
                  animate={{bottom: 0, opacity: 1}}
                  transition={{type: 'spring', delay: .1, duration: 1}}
                  className={styles.MokapBlock}>
                  <LazyLoadImage
                    placeholderSrc={placeholdermk1}
                    effect='blur'
                    width={'100%'}
                    height={400}
                    src={mk1} alt=""/>
                </motion.div>
                <motion.div
                  initial={{bottom: -180, opacity: 0}}
                  animate={{bottom: -40, opacity: 1}}
                  transition={{type: 'spring', delay: .2, duration: 1}}
                  className={styles.MokapBlock}>
                  <LazyLoadImage
                    placeholderSrc={placeholdermk1}
                    effect='blur'
                    width={'100%'}
                    height={400}
                    src={mk2} alt=""/>
                </motion.div>
                <motion.div
                  initial={{bottom: -140, opacity: 0}}
                  animate={{bottom: 0, opacity: 1}}
                  transition={{type: 'spring', duration: 1}}
                  className={styles.MokapBlock}>
                  <LazyLoadImage
                    placeholderSrc={placeholdermk1}
                    effect='blur'
                    width={'100%'}
                    height={400}
                    src={mk3} alt=""/>
                </motion.div>
              </>  : null
            }
          </AnimatePresence>
        </motion.div>

      </div>

      <div ref={ref} className={styles.ContentBody}>
        <span className={styles.ContentBodyTitle}>PLATORA.Engineering</span>
        <SectionList list={blockArray} id={'ENG'}/>
      </div>

    </div>
  );
};

export default Index;
