import styles from './App.module.scss'

import {Route, Routes} from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./pages/home";
import About from "./pages/about";
import Page404 from "./pages/Page404";
import {useEffect, useState} from "react";

import {m, LazyMotion, domAnimation, AnimatePresence} from "framer-motion";




function App() {
  const [chenckFonts, setCheckFonts] = useState(true)

  useEffect(()=>{
    uploadFonts()
  },[])

  const uploadFonts = async () =>{
    await document.fonts.ready.then(function () {
      setTimeout(()=>{
        setCheckFonts(false)
      }, 0)
    });
  }

  return (
    <>
      {
        chenckFonts ?
          <AnimatePresence>
            {
              chenckFonts &&
              <m.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <span className={styles.cringe1}>1</span>
                <span className={styles.cringe2}>1</span>
                <span className={styles.cringe3}>1</span>
                <div className={styles.preloadFIELD}>

                </div>
              </m.div>
            }
          </AnimatePresence>
          :
          <Routes>
            <Route path={'/'} element={<Layout/>}>
              <Route index element={<Home/>}/>
              <Route path={'about'} element={<About/>}/>
              <Route path={'*'} element={<Page404/>}/>
            </Route>
          </Routes>
      }
    </>
  );
}

export default App;
