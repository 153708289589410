import React, {useRef} from 'react';

import styles from './style.module.scss'
import {motion, useInView} from "framer-motion";

const Index = ({list, id}) => {

  const ref = useRef(null)
  const isInView = useInView(ref, {once: true})


  return (
    <motion.div className={styles.listWrapper} ref={ref}>
      {
        isInView &&
        list.map((item, index)=>
          <motion.div className={styles.listBlock}>
            <motion.div className={styles.listBlockHead} style={id==='EDU' ? {color: 'white'}: {color: '#2D2D2D'}}>
              <motion.img initial={{
                  opacity: 0,
                  bottom: '-120%',
                  position: 'absolute'
                }} animate={{
                  opacity: 1,
                  position: 'relative',
                  bottom: 0,
                  transition: {
                    duration: 1.1,
                    type: 'spring',
                    delay: index === 0 ? 0.10 : index === 1 ? 0.4 : index === 2 ? 0.70 : 4
                  }
                }} src={item.img} alt=""/>
              <motion.span initial={{
                opacity: 0,
                bottom: '-120%',
                position: 'absolute'
              }} animate={{
                              opacity: 1,
                              position: 'relative',
                              bottom: 0,
                              transition: {
                                duration: 1.1,
                                type: 'spring',
                                delay: index === 0 ? 0.20 : index === 1 ? 0.5 : index === 2 ? 0.80 : 4
                              }
                            }}>{item.title}</motion.span>
            </motion.div>
            <motion.span style={id==='EDU' ? {color: '#C8C8C8'}: {color: '#696969'}} initial={{
                opacity: 0,
                bottom: '-20px',
                position: 'absolute'
              }} animate={{
                opacity: 1,
                position: 'relative',
                bottom: 0,
                transition: {
                  duration: 1.1,
                  type: 'spring',
                  delay: index === 0 ? 0.30 : index === 1 ? 0.6 : index === 2 ? 0.90 : 4
                }
              }} className={styles.listBodyText}>
              {item.body}
            </motion.span>
          </motion.div>
        )
      }
    </motion.div>
  );
};

export default Index;