import React, {useRef} from 'react';

import styles from './style.module.scss'
import {AnimatePresence, useInView, motion} from "framer-motion";

import bodyBg from './BodyBg.png'

import headBg from './HeadBg.png'

import prxBg from './FINBG.png'

const Index = ({blockArray}) => {


  const ref = useRef(null)
  const isHeaderView = useInView(ref, {once: true})

  const BodyRef = useRef(null)
  const isBodyView = useInView(BodyRef, {once: true})

  const BodyHeadRef = useRef(null)
  const isBodyHeadView = useInView(BodyHeadRef, {once: true})

  const BodyContentRef = useRef(null)
  const isBodyContentView = useInView(BodyContentRef, {once: true})


  return (
    <div className={styles.sectionWrapper}>

      <div className={styles.sectionBg}>
        <div className={styles.sectionBgWrapper}>
            <img src={prxBg} alt="bgImage"/>
        </div>
        <div className={styles.sectionBgGradient}/>

        <div className={styles.sectionHeadContent}>
          <div className={styles.sectionLogoWrapper} style={{ backgroundImage: `url(${headBg})` }}>
            <div className={styles.sectionLogoField}>
              <AnimatePresence>
                {
                  isHeaderView &&
                  <>
                    <motion.svg
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 1
                        }
                      }}
                      width="291" height="289" viewBox="0 0 291 289" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M123.68 60.5341L39.0039 144.499L77.3661 8.6946C78.0595 6.26568 79.5341 4.127 81.5662 2.60302C83.5982 1.07903 86.0768 0.252923 88.6259 0.250016H186.11C189.658 0.248047 193.203 0.428602 196.732 0.79095C199.767 1.09147 202.802 1.5122 205.837 2.05313C215.321 3.78685 224.514 6.82124 233.152 11.0687C238.681 13.822 243.943 17.0712 248.873 20.7755C252.164 23.2234 255.306 25.8626 258.282 28.6791L169.205 116.881L123.68 60.5341Z" fill="#4D69D4"/>
                      <path d="M291 104.112C291.01 125.622 284.273 146.604 271.719 164.16C259.166 181.715 241.416 194.976 220.922 202.111C217.205 203.41 213.415 204.494 209.571 205.356C203.784 206.661 197.895 207.475 191.968 207.791H189.237H123.347C116.692 231.114 102.523 251.646 82.9971 266.261C63.471 280.875 39.6571 288.773 15.1803 288.75H5.83251C4.93211 288.751 4.04377 288.545 3.2372 288.149C2.43062 287.753 1.72776 287.177 1.18377 286.466C0.639772 285.756 0.269444 284.93 0.101842 284.054C-0.0657593 283.178 -0.026074 282.276 0.217784 281.418L14.634 230.329L121.799 124.306L167.324 180.564L283.534 65.4648C284.505 67.899 285.415 70.3633 286.204 72.8877C289.408 82.9912 291.025 93.5219 291 104.112Z" fill="#4D69D4"/>

                    </motion.svg>



                    <div className={styles.LogoName}>
                      <motion.span
                        initial={{
                          opacity: 0,
                          position: "absolute",
                          bottom: '-50px'
                        }}
                        animate={{
                          position: 'relative',
                          bottom: 0,
                          opacity: 1,
                          transition: {
                            duration: 1
                          }
                        }}
                      >FINANCE</motion.span>
                      <motion.span
                        initial={{
                          opacity: 0,
                          position: "absolute",
                          bottom: '-50px'
                        }}
                        animate={{
                          position: 'relative',
                          bottom: 0,
                          opacity: 1,
                          transition: {
                            duration: 1
                          }
                        }}
                      >IT-платформа для взаимодействия <br/> финансовых технологий с капиталами</motion.span>
                    </div>
                  </>
                }
              </AnimatePresence>

              <div ref={ref} className={styles.headerRef}/>
            </div>


          </div>
          <div className={styles.sectionHeadContentMokaps}>

            <AnimatePresence>

              {
                isBodyView &&
                <div className={styles.mokapWrapper}>

                </div>
              }

            </AnimatePresence>





          </div>
        </div>
      </div>

      <div className={styles.sectionBody} ref={BodyRef} style={{ backgroundImage: `url(${bodyBg})` }}>
        <div className={styles.sectionBodyLogo} ref={BodyHeadRef}>
          <AnimatePresence>
            {
              isBodyHeadView &&
              <motion.span
                initial={{
                  opacity: 0,
                  bottom: '-50px'
                }}
                animate={{
                  opacity: 1,
                  bottom: 0,
                  transition: {
                    duration: 1,
                  }
                }}
              >PLATORA.Finance</motion.span>
            }
          </AnimatePresence>

        </div>

        <div className={styles.sectionBodyList}>


          <div ref={BodyContentRef} className={styles.sectionBodyRef}/>


          <AnimatePresence>
            {
              isBodyContentView &&
              blockArray.map((item, index)=>{
                return (
                  <div className={styles.sectionBodyBlock}>
                    <motion.div className={styles.BodyBlockImage}
                                initial={{
                                  scale: 0
                                }}
                                animate={{
                                  scale: 1,
                                  transition: {
                                    duration: .8,
                                    delay: index === 0 ? 0.2 : index === 1 ? 0.8 : index === 2 ? 1.4 : 5
                                  }
                                }}
                    >
                      <img src={item.img} alt="itemImage"/>
                    </motion.div>
                    <div className={styles.BodyBlockTitleWrapper}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          bottom: '-120%',
                          // position: 'absolute'
                        }}
                        animate={{
                          opacity: 1,
                          // position: 'relative',
                          bottom: 0,
                          transition: {
                            duration: 1.1,
                            delay: index === 0 ? 0.3 : index === 1 ? 0.9 : index === 2 ? 1.5 : 4
                          }
                        }}
                      >{item.title}</motion.div>
                      <motion.div
                        initial={{
                          opacity: 0,
                          bottom: '-120%',
                          // position: 'absolute'
                        }}
                        animate={{
                          opacity: 1,
                          // position: 'relative',
                          bottom: 0,
                          transition: {
                            duration: 1.1,
                            delay: index === 0 ? 0.5 : index === 1 ? 1.1 : index === 2 ? 1.7 : 4
                          }
                        }}
                      >{item.body}</motion.div>
                    </div>
                  </div>
                )
              })
            }
          </AnimatePresence>


        </div>
      </div>

    </div>
  );
};

export default Index;