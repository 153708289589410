import React, {useRef, useState} from 'react';

import styles from './style.module.scss'
import {motion} from "framer-motion";
import {Alert, Box, Checkbox, CircularProgress, Snackbar} from "@mui/material";
import emailjs from "@emailjs/browser";
import Orbit from "../../../orbit";

const Index = () => {

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [sending, setSending] = useState(true)

  const [terms, setTerms] = useState(false)
  const handleChange = (event) => {
    setTerms(event.target.checked);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(false)

    emailjs.sendForm('service_z4k5x7l', 'template_ss4vblc', form.current, 'gcaxzmryhrtenfxa')
      .then(() => {
        handleClick()
        setSending(true)
        document.getElementById("myForm").reset();
      }, () => {
        handleClick()
        setSending(true)
        document.getElementById("myForm").reset();
      });
  };

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerLabel}>
        <span>
          Готовы <br/> к сотрудничеству
        </span>

       <Orbit/>
      </div>

      <form ref={form} onSubmit={sendEmail} id="myForm">

        <div className={styles.footerForm}>

          <div className={styles.formField}>
            <span>ФИО</span>
            <input disabled={!sending} required type="text" name="user_name" placeholder='Укажите ФИО'/>
          </div>

          <div className={styles.formField}>
            <span>Почта</span>
            <input disabled={!sending} required type="email" name="user_email" placeholder='Укажите почту'/>
          </div>

          <div className={styles.formField}>
            <span>Сообщение</span>
            <textarea disabled={!sending} required name="message" placeholder='Введите текст сообщения'/>
          </div>

          <div className={ styles.formFooter}>

            <motion.button
              disabled={!terms}
              type="submit" value="Send"

              className={!terms && sending ? styles.buttonDisabled : styles.buttonEnabled}

              initial={{
                scale: 0.98
              }}
              whileHover={{
                scale: terms ? 1 : 0.98
              }}
              whileTap={{
                scale: terms ? 0.98 : 0.96
              }}
            >
              {
                sending ? 'ОТПРАВИТЬ' : <CircularProgress color='inherit' size={30}/>
              }
            </motion.button>


            <Box display='flex' alignItems='center' className={styles.toolWrapper}>
              <Checkbox
                checked={terms} onChange={handleChange}
                sx={{


                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },
                }}
              />
              <div className={styles.formFooterTerms}>
                Нажимая кнопку “ОТПРАВИТЬ” Вы соглашаетесь с <span> Политикой конфиденциальности</span>
              </div>
            </Box>





          </div>


        </div>

      </form>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          <span className={styles.snap}>Сообщение успешно отправлено!</span>
        </Alert>
      </Snackbar>

    </footer>
  );
};

export default Index;