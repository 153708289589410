import React from 'react';

import styles from './style.module.scss'
import MainSection from "../../components/sections/mainSection";
import {useMediaQuery} from "@mui/material";
import EngineeringDesktop from "../../components/sections/desktop/EngineeringDesktop";
import EducationDesktop from "../../components/sections/desktop/EducationDesktop";
import FinancialDesktop from "../../components/sections/desktop/FinancialDesktop";
import EngineeringTablet from "../../components/sections/tablet/EngineeringTablet";
import EngineeringMobile from "../../components/sections/mobile/EngineeringMobile";
import EducationTablet from "../../components/sections/tablet/EducationTablet";
import FinancialTablet from "../../components/sections/tablet/FinancialTablet";
import EducationMobile from "../../components/sections/mobile/EducationMobile";
import FinancialMobile from "../../components/sections/mobile/FinancialMobile";
import FooterDesktop from "../../components/sections/desktop/FooterDesktop";
import FooterTablet from "../../components/sections/tablet/FooterTablet";

import ENGIMG1 from '../../images/ENG1.png'
import ENGIMG2 from '../../images/ENG2.png'
import ENGIMG3 from '../../images/ENG3.png'

import EDUIMG1 from '../../images/EDU1.png'
import EDUIMG2 from '../../images/EDU2.png'
import EDUIMG3 from '../../images/EDU3.png'

import FINIMG1 from '../../images/FIN1.png'
import FINIMG2 from '../../images/FIN2.png'
import FINIMG3 from '../../images/FIN3.png'

import {AnimateSharedLayout, motion} from "framer-motion";

const Index = () => {



  const isDesktop = useMediaQuery('(min-width: 1200px)')
  const isTablet= useMediaQuery('(min-width: 601px) and (max-width: 1199px)')
  const isMobile= useMediaQuery('(max-width: 600px)')

  const ENGARRAY = [
    {
      img: ENGIMG3,
      title: 'Независимый технадзор в режиме реального времени',
      body: 'Современная цифровая-система онлайн мониторинга критически важных этапов строительства. Своевременное выявление отклонений и надёжный контроль качества выполнения работ',
    },
    {
      img: ENGIMG1,
      title: 'Стандартизация и методология',
      body: 'Стандартизированная структура этапов и сроков исполнения строительных работ. Детализированная документация и методология взаимодействия с поставщиками и подрядчиками',
    },
    {
      img: ENGIMG2,
      title: 'Информационно-технологическая база',
      body: 'Цифровой реестр актуальных стандартов СНИП, ГОСТ, DIN, PE с расширенными комментариями и рекомендациями к применению от лучших отраслевых специалистов',
    },

  ];

  const EDUARRAY = [
    {
      img: EDUIMG1,
      title: 'Профориентирование \n' +
        'и подбор ВУЗа',
      body:'Актуальный рэнкинг ВУЗов и направлений подготовки специалистов. Помощь в выборе будущей профессии и выборе наиболее подходящего ВУЗа'
    },
    {
      img: EDUIMG2,
      title: 'Взаимодействие науки и бизнеса',
      body:'Интеграция научно-исследовательского потенциала лучших ВУЗов страны и бизнеса в едином цифровом пространстве'
    },
    {
      img: EDUIMG3,
      title: 'Коммуникации для развития ВУЗа',
      body:'Образование, наука, организация работы командах, коммуникации и общение в специализированном приложении разработанным для ВУЗов РФ'
    },
  ];

  const FINARRAY = [
    {
      img: FINIMG1,
      title: 'Интеллектуальный финансовый ассистент',
      body: 'Алгоритмизированный советник в области инвестиций. Предиктивная аналитика и варианты развития событий. Высокая предсказательная точность решений'
    },
    {
      img: FINIMG2,
      title: 'Управление активами и валютным портфелем',
      body: 'Трехуровневая система управления капиталами и правилами ведения сделок. Профессиональный инструментарий в доступном формате.'

    },
    {
      img: FINIMG3,
      title: 'Достоверные и актуальные данные, предоставленные профессионалами.',
      body: 'Фундаментальный, технический и финансовый анализ. Структурированные данные и алгоритмизированная инфографика'
    },
  ];

  const logoInitialDeskTop = {
    opacity: 0,
    position: "absolute",
    bottom: '-50px'
  }
  const logoAnimateDeskTop = {
    position: 'relative',
    bottom: 0,
    opacity: 1,
    transition: {
      duration: 1,
      type: 'spring',
    }
  }

  const scaleInitial = {
    scale: 0,
    opacity: 0
  }
  const scaleAnimate = {
    scale: 1,
    opacity: 1,
    transition:{
      duration: 1,
        type: 'spring'
    }
  }





  return (
    <section className={styles.pageWrapper}>
      <MainSection/>
      {/**/}
      <AnimateSharedLayout>


        <article className={styles.sectionsWrapper}>

          {
            isDesktop &&
            <>
              <EducationDesktop blockArray={EDUARRAY} logoAnimate={logoAnimateDeskTop} logoInitial={logoInitialDeskTop}/>
              <EngineeringDesktop blockArray={ENGARRAY} logoAnimate={logoAnimateDeskTop} logoInitial={logoInitialDeskTop}/>
              <FinancialDesktop blockArray={FINARRAY} logoAnimate={logoAnimateDeskTop} logoInitial={logoInitialDeskTop}/>
              <FooterDesktop/>
            </>
          }

          {
            isTablet &&
            <>
              <EducationTablet blockArray={EDUARRAY}/>
              <EngineeringTablet blockArray={ENGARRAY}/>
              <FinancialTablet blockArray={FINARRAY}/>
              <FooterTablet/>
            </>
          }

          {
            isMobile &&
            <>
              <EducationMobile blockArray={EDUARRAY}/>
              <EngineeringMobile blockArray={ENGARRAY}/>
              <FinancialMobile blockArray={FINARRAY}/>
              <FooterTablet/>
            </>
          }

        </article>
      </AnimateSharedLayout>

    </section>
  );
};

export default Index;