import React, {useRef} from 'react';

import styles from './style.module.scss'
import {AnimatePresence, useInView, motion} from "framer-motion";

import bodyBg from "./BodyBg.png";

import headBg from "./HeadBg.png";

import prxBg from './ENGBG.jpg'
import {Parallax} from "react-parallax";

const Index = ({blockArray}) => {

  const ref = useRef(null)
  const isHeaderView = useInView(ref, {once: true})

  const BodyRef = useRef(null)
  const isBodyView = useInView(BodyRef, {once: true})

  const BodyHeadRef = useRef(null)
  const isBodyHeadView = useInView(BodyHeadRef, {once: true})

  const BodyContentRef = useRef(null)
  const isBodyContentView = useInView(BodyContentRef, {once: true})


  return (
    <div className={styles.sectionWrapper}>

      <div className={styles.sectionBg}>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={require('./ENGBG.jpg')}
          bgImageAlt="the dog"
          strength={-50}
        >
          <div className={styles.headerContent}>

          </div>

        </Parallax>
      </div>

      <div className={styles.sectionBody} ref={BodyRef} style={{ backgroundImage: `url(${bodyBg})` }}>
        <div className={styles.sectionBodyLogo} ref={BodyHeadRef}>
          <AnimatePresence>
            {
              isBodyHeadView &&
              <motion.span
                initial={{
                  opacity: 0,
                  bottom: '-50px'
                }}
                animate={{
                  opacity: 1,
                  bottom: 0,
                  transition: {
                    duration: 1,
                  }
                }}
              >PLATORA.Engineering</motion.span>
            }
          </AnimatePresence>

        </div>

        <div className={styles.sectionBodyList}>
          <div ref={BodyContentRef} className={styles.sectionBodyRef}/>


          <AnimatePresence>
            {
              isBodyContentView &&
              blockArray.map((item, index)=>{
                return (
                  <div className={styles.sectionBodyBlock}>
                    <motion.div className={styles.BodyBlockImage}
                                initial={{
                                  scale: 0
                                }}
                                animate={{
                                  scale: 1,
                                  transition: {
                                    duration: .8,
                                    delay: index === 0 ? 0.2 : index === 1 ? 0.8 : index === 2 ? 1.4 : 5
                                  }
                                }}
                    >
                      <img src={item.img} alt="itemImage"/>
                    </motion.div>
                    <div className={styles.BodyBlockTitleWrapper}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          bottom: '-120%',
                          position: 'absolute'
                        }}
                        animate={{
                          opacity: 1,
                          position: 'relative',
                          bottom: 0,
                          transition: {
                            duration: 1.1,
                            delay: index === 0 ? 0.3 : index === 1 ? 0.9 : index === 2 ? 1.5 : 4
                          }
                        }}
                      >{item.title}</motion.div>
                      <motion.div
                        initial={{
                          opacity: 0,
                          bottom: '-120%',
                          position: 'absolute'
                        }}
                        animate={{
                          opacity: 1,
                          position: 'relative',
                          bottom: 0,
                          transition: {
                            duration: 1.1,
                            delay: index === 0 ? 0.5 : index === 1 ? 1.1 : index === 2 ? 1.7 : 4
                          }
                        }}
                      >{item.body}</motion.div>
                    </div>
                  </div>
                )
              })
            }
          </AnimatePresence>


        </div>
      </div>

    </div>
  );
};

export default Index;