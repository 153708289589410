import React, {useEffect, useRef} from 'react';

import styles from './style.module.scss'
import {AnimatePresence, useInView, motion} from "framer-motion";

import bodyBg from './BodyBg.png'

import headBg from './HeadBg.png'

import prxBg from './HeadBg.png'

const Index = ({blockArray}) => {

  const ref = useRef(null)
  const isHeaderView = useInView(ref, {once: true})

  const BodyRef = useRef(null)
  const isBodyView = useInView(BodyRef, {once: true})

  const BodyHeadRef = useRef(null)
  const isBodyHeadView = useInView(BodyHeadRef, {once: true})

  const BodyContentRef = useRef(null)
  const isBodyContentView = useInView(BodyContentRef, {once: true})




  return (
    <div className={styles.sectionWrapper}>

      <div className={styles.sectionBg}>
        <div className={styles.sectionBgWrapper}>
            <img src={prxBg} alt="bgImage"/>
        </div>
        <div className={styles.sectionBgGradient}/>

        <div className={styles.sectionHeadContent}>
          <div className={styles.sectionLogoWrapper} style={{ backgroundImage: `url(${headBg})` }}>
            <div className={styles.sectionLogoField}>
              <AnimatePresence>
                {
                  isHeaderView &&
                  <>
                    <motion.svg
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 1
                        }
                      }}
                      width="290" height="289" viewBox="0 0 290 289" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M140.582 101.548C146.476 117.185 144.703 134.098 142.147 145.593C132.707 138.603 120.26 126.988 114.331 111.282C108.473 95.6628 110.246 78.7663 112.767 67.2891C122.241 74.3135 134.741 85.9287 140.582 101.548V101.548Z" fill="#0BA438"/>
                      <path d="M284.965 72.8857C284.252 70.5903 283.418 68.3294 282.531 66.1202C282.531 66.1202 268.102 28.1333 231.942 11.1677C223.406 7.16366 214.4 3.88446 204.873 2.08953C201.918 1.53725 198.928 1.12303 195.868 0.82963C192.374 0.484451 188.845 0.277344 185.298 0.277344H88.239C83.0236 0.277344 78.4688 3.74639 77.0433 8.71697L47.2458 114.324L0.219972 280.96C-0.823114 284.601 1.97583 288.277 5.81787 288.277H15.2056C65.2042 288.277 109.083 255.347 122.835 207.626L135.3 169.484C123.252 161.752 101.712 145.045 92.1158 119.502C78.6948 83.8106 94.2716 45.9963 94.9669 44.4085L99.8521 32.7587L111.187 38.3678C112.752 39.1272 149.451 57.5943 162.872 93.2858C172.607 119.278 167.027 146.409 162.994 159.819L163.028 159.854L148.512 207.609H186.098V207.575C186.845 207.575 187.541 207.575 188.288 207.54H188.41C189.297 207.505 190.218 207.488 191.139 207.419C197.155 207.091 202.978 206.28 208.646 204.986H208.681C212.557 204.14 216.33 203.053 219.998 201.758C260.626 187.589 289.781 149.153 289.781 103.935C289.781 93.0787 288.129 82.6543 285.017 72.8512L284.965 72.8857ZM216.573 96.4614C199.032 109.078 175.198 106.109 175.198 106.109C175.198 106.109 179.752 82.6715 197.294 70.0553C214.8 57.439 238.669 60.4075 238.669 60.4075C238.669 60.4075 234.115 83.8452 216.573 96.4614Z" fill="#0BA438"/>

                    </motion.svg>



                    <div className={styles.LogoName}>
                      <motion.span
                        initial={{
                          opacity: 0,
                          position: "absolute",
                          bottom: '-50px'
                        }}
                        animate={{
                          position: 'relative',
                          bottom: 0,
                          opacity: 1,
                          transition: {
                            duration: 1
                          }
                        }}
                      >EDUCATION</motion.span>
                      <motion.span
                        initial={{
                          opacity: 0,
                          position: "absolute",
                          bottom: '-50px'
                        }}
                        animate={{
                          position: 'relative',
                          bottom: 0,
                          opacity: 1,
                          transition: {
                            duration: 1
                          }
                        }}
                      >Цифровой реестр Высших Учебных Заведений РФ</motion.span>
                    </div>
                  </>
                }
              </AnimatePresence>

              <div ref={ref} className={styles.headerRef}/>
            </div>


          </div>
          <div className={styles.sectionHeadContentMokaps}>

            <AnimatePresence>

              {
                isBodyView &&
                <div className={styles.mokapWrapper}>

                </div>
              }

            </AnimatePresence>





          </div>
        </div>
      </div>

      <div className={styles.sectionBody} ref={BodyRef} style={{ backgroundImage: `url(${bodyBg})` }}>
        <div className={styles.sectionBodyLogo} ref={BodyHeadRef}>
          <AnimatePresence>
            {
              isBodyHeadView &&
              <motion.span
                initial={{
                  opacity: 0,
                  bottom: '-50px'
                }}
                animate={{
                  opacity: 1,
                  bottom: 0,
                  transition: {
                    duration: 1,
                  }
                }}
              >PLATORA.Education</motion.span>
            }
          </AnimatePresence>

        </div>

        <div className={styles.sectionBodyList}>
          <div ref={BodyContentRef} className={styles.sectionBodyRef}/>


          <AnimatePresence>
            {
              isBodyContentView &&
              blockArray.map((item, index)=>{
                return (
                  <div className={styles.sectionBodyBlock}>
                    <motion.div className={styles.BodyBlockImage}
                                initial={{
                                  scale: 0
                                }}
                                animate={{
                                  scale: 1,
                                  transition: {
                                    duration: .8,
                                    delay: index === 0 ? 0.2 : index === 1 ? 0.8 : index === 2 ? 1.4 : 5
                                  }
                                }}
                    >
                      <img src={item.img} alt="itemImage"/>
                    </motion.div>
                    <div className={styles.BodyBlockTitleWrapper}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          bottom: '-120%',
                          position: 'absolute'
                        }}
                        animate={{
                          opacity: 1,
                          position: 'relative',
                          bottom: 0,
                          transition: {
                            duration: 1.1,
                            delay: index === 0 ? 0.3 : index === 1 ? 0.9 : index === 2 ? 1.5 : 4
                          }
                        }}
                      >{item.title}</motion.div>
                      <motion.div
                        initial={{
                          opacity: 0,
                          bottom: '-120%',
                          position: 'absolute'
                        }}
                        animate={{
                          opacity: 1,
                          position: 'relative',
                          bottom: 0,
                          transition: {
                            duration: 1.1,
                            delay: index === 0 ? 0.5 : index === 1 ? 1.1 : index === 2 ? 1.7 : 4
                          }
                        }}
                      >{item.body}</motion.div>
                    </div>
                  </div>
                )
              })
            }
          </AnimatePresence>


        </div>
      </div>

    </div>
  );
};

export default Index;