import React from 'react';

import styles from './style.module.scss'

import {AnimatePresence, motion} from "framer-motion";
import {useMediaQuery} from "@mui/material";

import DeskGradient from './Group 5794.png'
import TabletGradient from './Tablet.png'

import MobileGradient from './Mobile.png'

const Index = () => {

  const isDesktop = useMediaQuery('(min-width: 1200px)')
  const isTablet= useMediaQuery('(min-width: 601px) and (max-width: 1199px)')
  const isMobile= useMediaQuery('(max-width: 600px)')

  return (
    <div className={styles.GradientWrapper}>
      {
        isDesktop &&
        <svg width="1703" height="989" viewBox="0 0 1703 989" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8" filter="url(#filter0_f_1303_2270)">
            <path d="M1226 401.507C1226 550.352 925.38 822 710.608 822C541.495 822 243 550.352 243 401.507C243 252.663 463.052 132 734.5 132C1005.95 132 1226 252.663 1226 401.507Z" fill="url(#paint0_linear_1303_2270)" fill-opacity="0.6"/>
            <path d="M1460 475.961C1460 616.718 1418.67 719 1291.65 719C1164.62 719 1000 616.718 1000 475.961C1000 335.204 1074.52 183 1201.55 183C1328.57 183 1429.18 262.48 1460 475.961Z" fill="url(#paint1_linear_1303_2270)" fill-opacity="0.6"/>
          </g>
          <defs>
            <filter id="filter0_f_1303_2270" x="0" y="-111" width="1703" height="1176" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="121.5" result="effect1_foregroundBlur_1303_2270"/>
            </filter>
            <linearGradient id="paint0_linear_1303_2270" x1="710.608" y1="132" x2="410.301" y2="509.874" gradientUnits="userSpaceOnUse">
              <stop stopColor="#0BA438"/>
              <stop offset="0.864583" stopColor="#EE9A4D"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1303_2270" x1="1118.56" y1="183" x2="1426.38" y2="692.391" gradientUnits="userSpaceOnUse">
              <stop offset="0.999391" stopColor="#4D69D4"/>
              <stop offset="1" stopColor="#0BA438"/>
            </linearGradient>
          </defs>
        </svg>

      }

      {
        isMobile &&
        <>
          <svg width="639" height="682" viewBox="0 0 639 682" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_369_53)">
              <path d="M438.947 293.735C438.947 386.925 343.226 557 274.84 557C220.992 557 125.947 386.925 125.947 293.735C125.947 200.545 196.015 125 282.447 125C368.88 125 438.947 200.545 438.947 293.735Z" fill="url(#paint0_linear_369_53)" fill-opacity="0.6"/>
            </g>
            <g filter="url(#filter1_f_369_53)">
              <path d="M513.947 327.939C513.947 420.639 500.741 488 460.148 488C419.555 488 366.947 420.639 366.947 327.939C366.947 235.239 390.762 135 431.354 135C471.947 135 504.097 187.344 513.947 327.939Z" fill="url(#paint1_linear_369_53)" fill-opacity="0.6"/>
            </g>
            <defs>
              <filter id="filter0_f_369_53" x="0.947266" y="0" width="563" height="682" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="62.5" result="effect1_foregroundBlur_369_53"/>
              </filter>
              <filter id="filter1_f_369_53" x="241.947" y="10" width="397" height="603" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="62.5" result="effect1_foregroundBlur_369_53"/>
              </filter>
              <linearGradient id="paint0_linear_369_53" x1="274.84" y1="125" x2="99.5889" y2="237.15" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0BA438"/>
                <stop offset="0.864583" stopColor="#EE9A4D"/>
              </linearGradient>
              <linearGradient id="paint1_linear_369_53" x1="404.834" y1="135" x2="628.42" y2="314.534" gradientUnits="userSpaceOnUse">
                <stop offset="0.999391" stopColor="#4D69D4"/>
                <stop offset="1" stopColor="#0BA438"/>
              </linearGradient>
            </defs>
          </svg>

        </>
      }


      {
        isTablet &&
        <>
          <motion.img
            // initial={{
            //   opacity: 0
            // }}
            // animate={{
            //   opacity: 1,
            //   transition: {
            //     duration: 1,
            //     // type: 'spring',
            //     delay: .7
            //   }
            // }}
            rel="preload"
            src={TabletGradient} alt=""/>
        </>
      }


    </div>
  );
};

export default Index;