import React from 'react';

import styles from './style.module.scss'
import {Link, useLocation, useNavigate} from "react-router-dom";

import {motion} from "framer-motion";

const Index = () => {

  const location = useNavigate()

  return (
    <div className={styles.pageWrapper}>
      <h1>Страница не найдена</h1>


      <motion.div
        initial={{
          scale: 1
        }}
        whileHover={{
          scale: 1.05
        }}
        whileTap={{
          scale: 0.96
        }}
        onClick={()=>{
        location('/')
      }}>
        <Link to={'/'}>Вернуться на главную</Link>

      </motion.div>
    </div>
  );
};

export default Index;