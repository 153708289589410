import React, {useRef} from 'react';

import styles from './style.module.scss'

import "swiper/css";
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import {motion, AnimatePresence, useInView} from "framer-motion";

import prxBg from './prxBg.png'

import parBg from './parbg.png'
import {LazyLoadImage} from "react-lazy-load-image-component";

import placeholdermk1 from "../../../../images/mobilePlaceholder.webp";
import mk1 from "../../../../images/MFINMK1.webp";
import mk2 from "../../../../images/MFINMK2.webp";
import mk3 from "../../../../images/MFINMK3.webp";
import mk4 from "../../../../images/MFINMK4.webp";
import mk5 from "../../../../images/MFINMK5.webp";


const Index = ({blockArray}) => {

  const ref = useRef(null)
  const isHeaderView = useInView(ref, {once: true})

  const BodyRef = useRef(null)
  const isBodyView = useInView(BodyRef, {once: true})

  const SliderRef = useRef(null)
  const isSliderRef = useInView(SliderRef, {once: true})

  const LogoRef = useRef(null)
  const isLogoRef = useInView(LogoRef, {once: true})


  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.sectionHead}>

        <div className={styles.sectionHeadBg}>
          <div className={styles.bgImage}>
            {/*<Parallax speed={-5}>*/}
              <img src={parBg} alt="bgImage"/>

            {/*</Parallax>*/}
          </div>

          <div className={styles.sectionHeadBgSecond}/>
          <div className={styles.sectionHeadBg}/>

          <div className={styles.headContent}>

            <div className={styles.headContentLogo}>
              <div className={styles.headRef} ref={ref}/>
              {
                isHeaderView &&
                <div>
                  <motion.svg
                    initial={{
                      opacity: 0,
                      scale: 0
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1
                    }}
                    transition={{
                      duration: 1,
                      type: "spring"
                    }}
                    width="159" height="158" viewBox="0 0 159 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M67.5788 32.9387L21.3125 78.8165L42.2733 4.61406C42.6522 3.28692 43.4579 2.11836 44.5682 1.28567C45.6785 0.452978 47.0327 0.00159704 48.4256 8.70291e-06H101.69C103.628 -0.00106705 105.566 0.0975866 107.494 0.295571C109.152 0.459772 110.81 0.689653 112.469 0.985215C117.651 1.9325 122.674 3.59047 127.393 5.91125C130.414 7.41564 133.29 9.19096 135.983 11.2149C137.782 12.5525 139.498 13.9945 141.124 15.5334L92.4532 63.7264L67.5788 32.9387Z" fill="#4D69D4"/>
                    <path d="M159 56.7491C159.005 68.5021 155.324 79.9668 148.465 89.5588C141.606 99.1508 131.908 106.397 120.71 110.295C118.679 111.005 116.608 111.597 114.508 112.068C111.346 112.781 108.128 113.226 104.89 113.398H103.397H67.3959C63.7596 126.142 56.0178 137.361 45.3489 145.346C34.68 153.331 21.6683 157.646 8.29437 157.634H3.18683C2.69486 157.635 2.20948 157.522 1.76878 157.306C1.32807 157.089 0.944035 156.774 0.646801 156.386C0.349566 155.998 0.147222 155.547 0.0556457 155.068C-0.0359304 154.59 -0.0142466 154.097 0.118995 153.628L7.99588 125.714L66.5501 67.7834L91.4245 98.5218L154.92 35.6328C155.451 36.9628 155.949 38.3093 156.38 39.6886C158.13 45.2091 159.014 50.963 159 56.7491Z" fill="#4D69D4"/>

                  </motion.svg>


                  <div className={styles.headContentLogoTitle}>
                    <motion.span
                      initial={{
                        opacity: 0,
                        position: "absolute",
                        bottom: '-50px'
                      }}
                      animate={{
                        position: 'relative',
                        bottom: 0,
                        opacity: 1,

                      }}
                      transition = {{
                        duration: 1,
                        type: 'spring',
                      }}
                    >FINANCE</motion.span>
                    <motion.span
                      initial={{
                        opacity: 0,
                        position: "absolute",
                        bottom: '-50px'
                      }}
                      animate={{
                        position: 'relative',
                        bottom: 0,
                        opacity: 1,

                      }}
                      transition = {{
                        duration: 1,
                        type: 'spring',
                      }}
                    >IT-платформа для взаимодействия <br/> финансовых технологий с капиталами</motion.span>
                  </div>
                </div>
              }
            </div>

            {
              isSliderRef &&
              <>
                <motion.div className={styles.headContentBody}
                            initial={{
                              opacity: 0,
                              marginBottom: '-100px',
                            }}
                            animate={{
                              opacity: 1,
                              marginBottom: 0,
                              transition: {
                                duration: 1,
                                type: 'spring'
                              }
                            }}
                >
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={0}
                    pagination={{
                      clickable: true,
                    }}
                    centeredSlides={true}
                    modules={[Pagination]}
                    className="FinancialSwiper"

                  >
                    <SwiperSlide className={styles.slide}>
                      <LazyLoadImage

                        rel='prefetch'
                        src={mk1} alt=""/>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                      <LazyLoadImage
                        rel='prefetch'
                        src={mk2} alt=""/>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                      <LazyLoadImage
                        rel='prefetch'
                        src={mk3} alt=""/>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                      <LazyLoadImage
                        rel='prefetch'
                        src={mk4} alt=""/>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                      <LazyLoadImage
                        wrapperClassName={styles.slideTablet}
                        rel='prefetch'
                        src={mk5} alt=""/>
                    </SwiperSlide>
                  </Swiper>
                </motion.div>
              </>
            }

            <div className={styles.SliderRef} ref={SliderRef}/>
          </div>


        </div>

        {/*style={{backgroundImage: `url(${bgImg.src})`}}*/}


      </div>

      <div className={styles.sectionBody} >

        <div className={styles.BodyLogo}>

          {
            isLogoRef &&
            <>
            <span>PLATORA</span>
            <div className={styles.LogoBg}>
              <motion.svg
                initial={{
                  marginLeft: -600
                }}
                animate={{
                  marginLeft: 0,
                  transition: {
                    duration: 1.2,
                    type: 'spring'
                  }
                }}
                width="565" height="61" viewBox="0 0 565 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M489.961 0H0V61H395.059L440.854 19.5L454.648 32L489.961 0ZM509.824 0L454.648 50L440.854 37.5L415.474 61H565V0H509.824Z" fill="url(#paint0_linear_77_674)"/>
                <defs>
                  <linearGradient id="paint0_linear_77_674" x1="1.32716e-07" y1="31" x2="565" y2="31" gradientUnits="userSpaceOnUse">
                    <stop offset="0.333333" stopColor="#4D69D4"/>
                    <stop offset="1" stopColor="#4D69D4" stopOpacity="0"/>
                  </linearGradient>
                </defs>
              </motion.svg>

              <span>Finance</span>
            </div>
            </>

          }

          <div className={styles.LogoRef} ref={LogoRef}/>

        </div>

        <div className={styles.BodyContent} >

          <div className={styles.BodyRef} ref={BodyRef}/>
          <AnimatePresence>
            {
              isBodyView &&
              blockArray.map((item, index)=>{
                return (
                  <div className={styles.BodyContentBlock} key={index}>
                    <img src={item.img} alt="itemImage"/>
                    <div className={styles.blockText}>
                      <div>{item.title}</div>
                      <div>{item.body}</div>
                    </div>
                  </div>
                )
              })
            }
          </AnimatePresence>


        </div>

      </div>

    </div>
  );
};

export default Index;